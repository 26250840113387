import React from 'react';

import { Route, RouteComponentProps } from 'react-router-dom';

import Visualization from 'client/app/apps/Visualization/Visualization';
import { VisualizationRouteProps, visualizationRoutes } from 'client/app/lib/nav/actions';

/**
 * Launches visserver in an iframe.
 */
export default function VisualizationApp() {
  return (
    <Route
      exact
      path={visualizationRoutes.base.pathTemplate}
      render={(props: RouteComponentProps<Partial<VisualizationRouteProps>>) => (
        <Visualization
          id={props.match.params.id ?? ''}
          visserverApp={props.match.params.visserverApp ?? ''}
          simulationID={props.match.params.simulationID ?? ''}
        />
      )}
    />
  );
}
