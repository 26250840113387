import React from 'react';

import { Route, RouteComponentProps, withRouter } from 'react-router-dom';

import WorkflowBuilder from 'client/app/apps/workflow-builder/WorkflowBuilder';
import { workflowRoutes } from 'client/app/lib/nav/actions';

type WorkflowRouteMatch = {
  workflowId: string;
};

/*
 * Show the workflow associated with the workflowId.
 */
const WorkflowScreen = React.memo(() => {
  return (
    <Route
      path={workflowRoutes.openInWorkflowBuilder.pathTemplate}
      render={(props: RouteComponentProps<Partial<WorkflowRouteMatch>>) => (
        <WorkflowBuilder workflowId={props.match.params.workflowId as WorkflowId} />
      )}
    />
  );
});

export default withRouter(WorkflowScreen);
