import React, { useState } from 'react';

import PolicyEditorDialog from 'client/app/apps/policy-library/components/EditorDialog';
import PolicyDialogStateProvider from 'client/app/apps/policy-library/components/PolicyDialogStateContext';
import PreviewPolicyDialog from 'client/app/apps/policy-library/components/PreviewDialog';
import useDeletePolicy from 'client/app/apps/policy-library/components/useDeletePolicy.hook';
import useOpenPolicyEditor from 'client/app/apps/policy-library/components/useOpenPolicyEditor.hook';
import PolicyLibraryAppNew from 'client/app/apps/policy-library/PolicyLibraryApp.new';
import PolicyLibraryAppOld from 'client/app/apps/policy-library/PolicyLibraryApp.old';
import DetailsView from 'client/app/components/Parameters/Policy/components/DetailsView';
import { LiquidPolicy } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';

function PolicyLibraryScreen() {
  const isEnabledLiquidPolicyLibrary = useFeatureToggle('LIQUID_POLICY_LIBRARY');

  const [policy, setPolicy] = useState<LiquidPolicy | undefined>();

  const deleteLiquidPolicy = useDeletePolicy();
  const editLiquidPolicy = useOpenPolicyEditor('edit');
  const copyLiquidPolicy = useOpenPolicyEditor('create');

  return isEnabledLiquidPolicyLibrary ? (
    <>
      <PolicyLibraryAppNew
        onViewDetails={setPolicy}
        onDelete={deleteLiquidPolicy}
        onEdit={editLiquidPolicy}
        onCopy={copyLiquidPolicy}
      />
      {policy && (
        <PreviewPolicyDialog policy={policy} onClose={() => setPolicy(undefined)}>
          <DetailsView policy={policy} />
        </PreviewPolicyDialog>
      )}
      <PolicyEditorDialog />
    </>
  ) : (
    <PolicyLibraryAppOld />
  );
}

export default function PolicyLibraryWithState() {
  return (
    <PolicyDialogStateProvider>
      <PolicyLibraryScreen />
    </PolicyDialogStateProvider>
  );
}
