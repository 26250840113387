import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react';

import { v4 as uuid } from 'uuid';

import { useProtocolsParamState } from 'client/app/apps/protocols/lib/utils';
import { Markdown } from 'common/lib/markdown';
import { Parameter } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import {
  ProtocolStep,
  ProtocolStepInput,
  ProtocolStepOutput,
} from 'common/types/Protocol';
import {
  ElementPath,
  getElementId,
  getElementParameterName,
  newElementPath,
  Schema,
  SchemaInput,
  SchemaOutput,
} from 'common/types/schema';

/**
 * StepState represents the state of a step both in terms of presentation in
 * the protocol and function in the workflow schema. As such it is meant to be
 * the single source of truth for step information
 */
type StepState = {
  id: string;
  displayName: string;
  inputs: InputStepState[];
  outputs: OutputStepState[];
};

type InputStepState = {
  id: string;
  typeName: string;
  path: ElementPath;
  displayName: string;
  default?: any;
  contextId?: string;
  displayDescription?: Markdown;
  configuration?: ParameterEditorConfigurationSpec;
};

type OutputStepState = {
  id: string;
  typeName: string;
  path: ElementPath;
  displayName: string;
};

const newStepStates = (schema: Schema, steps: ProtocolStep[]) => {
  const temporaryPath = newElementPath('temporary', 'entry');

  // notice that we init with a temporary path, update based on schema and
  // remove any temporary paths afterwards. This means the schema is the source
  // of truth on re-initialisation
  const stepStates = steps.map<StepState>(step => {
    return {
      id: step.id,
      displayName: step.displayName,
      errors: [],
      inputs: step.inputs.map<InputStepState>(input => {
        return {
          id: input.id,
          path: temporaryPath,
          typeName: '',
          displayName: input.displayName,
          displayDescription: input.displayDescription,
          configuration: input.configuration,
        };
      }),
      outputs: step.outputs.map<OutputStepState>(output => {
        return {
          id: output.id,
          path: temporaryPath,
          typeName: '',
          displayName: output.displayName,
        };
      }),
    };
  });

  schema.inputs?.forEach(input => {
    stepStates.some(step => {
      const state = step.inputs.find(({ id }) => id === input.id);
      if (state) {
        state.path = input.path;
        state.typeName = input.typeName;
        state.default = input.default;
        state.contextId = input.contextId;
        return true;
      }
      return false;
    });
  });

  schema.outputs?.forEach(output => {
    stepStates.some(step => {
      const state = step.outputs.find(({ id }) => id === output.id);
      if (state) {
        state.path = output.path;
        state.typeName = output.typeName;
        return true;
      }
      return false;
    });
  });

  stepStates.forEach(step => {
    step.inputs = step.inputs.filter(({ path }) => path !== temporaryPath);
    step.outputs = step.outputs.filter(({ path }) => path !== temporaryPath);
  });

  return stepStates;
};

type StepStateUpdate = {
  input?: {
    add?: InputStepState;
    updateByIndex?: { index: number; value?: InputStepState };
    removeByPath?: ElementPath;
  };
  output?: {
    add?: OutputStepState;
    updateByIndex?: { index: number; value?: OutputStepState };
    removeByPath?: ElementPath;
  };
};

const updateStepStates = (steps: StepState[], index: number, opts: StepStateUpdate) => {
  const step = steps[index];
  const update = { ...step };
  const { input, output } = opts;
  if (input) {
    const { add, updateByIndex, removeByPath } = input;
    if (add) {
      update.inputs = [...update.inputs, add];
    }
    if (updateByIndex) {
      const { index: inputIndex, value } = updateByIndex;
      update.inputs =
        value === undefined
          ? update.inputs.toSpliced(inputIndex, 1)
          : update.inputs.toSpliced(inputIndex, 1, value);
    }
    if (removeByPath) {
      update.inputs = update.inputs.filter(
        i =>
          getElementId(i.path) !== getElementId(removeByPath) ||
          getElementParameterName(i.path) !== getElementParameterName(removeByPath),
      );
    }
  }
  if (output) {
    const { add, updateByIndex, removeByPath } = output;
    if (add) {
      update.outputs = [...update.outputs, add];
    }
    if (updateByIndex) {
      const { index: outputIndex, value } = updateByIndex;
      update.outputs =
        value === undefined
          ? update.outputs.toSpliced(outputIndex, 1)
          : update.outputs.toSpliced(outputIndex, 1, value);
    }
    if (removeByPath) {
      update.outputs = update.outputs.filter(
        i =>
          getElementId(i.path) !== getElementId(removeByPath) ||
          getElementParameterName(i.path) !== getElementParameterName(removeByPath),
      );
    }
  }
  return steps.toSpliced(index, 1, update);
};

type StepsContextType = {
  workflowSchema: Schema;
  protocolSteps: ProtocolStep[];
  selectedStep?: ProtocolStep;
  createStep: () => void;
  handleSelectStep: (step: ProtocolStep) => void;
  updateStepName: (step: ProtocolStep) => (name: string) => void;
  updateStepInput: (
    step: ProtocolStep,
  ) => (index: number, opts: { name?: string; description?: Markdown }) => void;
  updateStepOutput: (step: ProtocolStep) => (index: number, name: string) => void;
  reorderSteps: (step: ProtocolStep[]) => void;
  reorderStepInputs: (step: ProtocolStep) => (orderedInputIds: string[]) => void;
  toggleStepInput: (
    step: ProtocolStep,
    elementInstanceId: string,
  ) => (param: Parameter, value: any, checked: boolean) => void;
  toggleStepOutput: (
    step: ProtocolStep,
    elementInstanceId: string,
  ) => (param: Parameter, checked: boolean) => void;
  deleteStep: (stepId: string) => void;
  deleteStepInput: (step: ProtocolStep) => (index: number) => void;
  deleteStepOutput: (step: ProtocolStep) => (index: number) => void;
};

export const StepsContext = createContext<StepsContextType | undefined>(undefined);

export const useStepsContext = () => {
  const context = useContext(StepsContext);

  if (context === undefined) {
    throw new Error('useStepsContext must be used within a StepsProvider');
  }

  return context;
};

type StepsProviderProps = {
  schema: Schema;
  steps: ProtocolStep[];
} & PropsWithChildren;

export const StepsProvider: FC<StepsProviderProps> = ({ schema, steps, children }) => {
  // lazy initial state instead of a reducer. The state we manage is not too
  // complex and involves updating and splicing arrays, which isn't more
  // convenient using dispatch updates
  //
  // Workflow schema provides the functional aspect of a step, while protocol
  // (instance) steps provide the presentational aspect. Other components will
  // need to potentially update either entity without triggering re-renders or
  // race conditions. Hence these internal steps are the single source of truth.
  const [stepStates, setStepStates] = useState<StepState[]>(() =>
    newStepStates(schema, steps),
  );

  const workflowSchema: Schema = useMemo(() => {
    return {
      inputs: stepStates.flatMap<SchemaInput>(({ inputs }) =>
        inputs.map(i => {
          return {
            id: i.id,
            typeName: i.typeName,
            path: i.path,
            default: i.default,
            contextId: i.contextId,
          };
        }),
      ),
      outputs: stepStates.flatMap<SchemaOutput>(({ outputs }) =>
        outputs.map(o => {
          return { id: o.id, typeName: o.typeName, path: o.path };
        }),
      ),
    };
  }, [stepStates]);

  const protocolSteps: ProtocolStep[] = useMemo(() => {
    return stepStates.map(state => {
      return {
        id: state.id,
        displayName: state.displayName,
        // getElementId(i.path)! since path must be defined and we only support
        // element paths atm
        inputs: state.inputs.map<ProtocolStepInput>(i => {
          return {
            id: i.id,
            elementInstanceId: getElementId(i.path)!,
            displayName: i.displayName,
            displayDescription: i.displayDescription || ('' as Markdown),
            configuration: i.configuration,
          };
        }),
        outputs: state.outputs.map<ProtocolStepOutput>(o => {
          return {
            id: o.id,
            elementInstanceId: getElementId(o.path)!,
            displayName: o.displayName,
          };
        }),
      };
    });
  }, [stepStates]);

  const { selectedStep, handleSelectStep } = useProtocolsParamState(protocolSteps);

  const createStep = () =>
    setStepStates([
      ...stepStates,
      {
        id: uuid(),
        displayName: `New Step ${stepStates.length + 1}`,
        inputs: [],
        outputs: [],
      },
    ]);

  const reorderSteps = (orderedSteps: ProtocolStep[]) => {
    const orderedIds = orderedSteps.map(({ id }) => id);
    const result = [...stepStates].sort(
      (a, b) => orderedIds.indexOf(a.id) - orderedIds.indexOf(b.id),
    );
    setStepStates(result);
  };

  const reorderStepInputs = (step: ProtocolStep) => (orderedInputIds: string[]) => {
    const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
    if (stateIndex > -1) {
      const stepState = stepStates[stateIndex];
      const inputs = [...stepState.inputs].sort(
        (a, b) => orderedInputIds.indexOf(a.id) - orderedInputIds.indexOf(b.id),
      );
      const result = stepStates.toSpliced(stateIndex, 1, { ...stepState, inputs });
      setStepStates(result);
    }
  };

  const updateStepName = (step: ProtocolStep) => (name: string) => {
    const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
    if (stateIndex > -1) {
      const after: StepState = { ...stepStates[stateIndex], displayName: name };
      const result = stepStates.toSpliced(stateIndex, 1, after);
      setStepStates(result);
    }
  };

  const updateStepInput =
    (step: ProtocolStep) =>
    (index: number, opts: { name?: string; description?: Markdown }) => {
      const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
      if (stateIndex > -1) {
        const before = stepStates[stateIndex].inputs[index];
        const after: InputStepState = { ...before };
        if (opts.name) {
          after.displayName = opts.name;
        }
        if (opts.description) {
          after.displayDescription = opts.description;
        }
        const result = updateStepStates(stepStates, stateIndex, {
          input: { updateByIndex: { index, value: after } },
        });
        setStepStates(result);
      }
    };

  const updateStepOutput = (step: ProtocolStep) => (index: number, name: string) => {
    const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
    if (stateIndex > -1) {
      const before = stepStates[stateIndex].outputs[index];
      const after: OutputStepState = { ...before, displayName: name };
      const result = updateStepStates(stepStates, stateIndex, {
        output: { updateByIndex: { index, value: after } },
      });
      setStepStates(result);
    }
  };

  const toggleStepInput =
    (step: ProtocolStep, elementInstanceId: string) =>
    (param: Parameter, value: any, checked: boolean) => {
      const index = stepStates.findIndex(({ id }) => id === step.id);
      if (index > -1) {
        const result = updateStepStates(stepStates, index, {
          input: {
            add: checked
              ? {
                  id: uuid(),
                  path: newElementPath(elementInstanceId, param.name),
                  typeName: param.type,
                  displayName: param.configuration?.displayName || param.name,
                  // do not use param.configuration?.displayDescription, which
                  // is massive and not a nice default state
                  displayDescription: '' as Markdown,
                  configuration: param.configuration?.editor,
                  default: value,
                }
              : undefined,
            removeByPath: !checked
              ? newElementPath(elementInstanceId, param.name)
              : undefined,
          },
        });
        setStepStates(result);
      }
    };

  const toggleStepOutput =
    (step: ProtocolStep, elementInstanceId: string) =>
    (param: Parameter, checked: boolean) => {
      const index = stepStates.findIndex(({ id }) => id === step.id);
      if (index > -1) {
        const result = updateStepStates(stepStates, index, {
          output: {
            add: checked
              ? {
                  id: uuid(),
                  path: newElementPath(elementInstanceId, param.name),
                  typeName: param.type,
                  displayName: param.configuration?.displayName || param.name,
                }
              : undefined,
            removeByPath: !checked
              ? newElementPath(elementInstanceId, param.name)
              : undefined,
          },
        });
        setStepStates(result);
      }
    };

  const deleteStep = (stepId: string) => {
    const result = stepStates.filter(({ id }) => id !== stepId);
    setStepStates(result);
  };

  const deleteStepInput = (step: ProtocolStep) => (index: number) => {
    const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
    if (stateIndex > -1) {
      const result = updateStepStates(stepStates, stateIndex, {
        input: { updateByIndex: { index, value: undefined } },
      });
      setStepStates(result);
    }
  };

  const deleteStepOutput = (step: ProtocolStep) => (index: number) => {
    const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
    if (stateIndex > -1) {
      const result = updateStepStates(stepStates, stateIndex, {
        output: { updateByIndex: { index, value: undefined } },
      });
      setStepStates(result);
    }
  };

  const state = {
    workflowSchema,
    protocolSteps,
    selectedStep,
    createStep,
    handleSelectStep,
    updateStepName,
    updateStepInput,
    updateStepOutput,
    reorderSteps,
    reorderStepInputs,
    toggleStepInput,
    toggleStepOutput,
    deleteStep,
    deleteStepInput,
    deleteStepOutput,
  };

  return <StepsContext.Provider value={state}>{children}</StepsContext.Provider>;
};
