import React from 'react';

import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';

import { OutputEntity } from 'client/app/components/ElementPlumber/ElementOutputs/types';
import { DataTable } from 'common/types/spreadsheetEditor';
import Colors from 'common/ui/Colors';

type OutputDataTableProps = {
  table: DataTable;
  entityView: OutputEntity;
};

export default function OutputDataTable(props: OutputDataTableProps) {
  const { table, entityView } = props;
  const content =
    entityView === 'rows' ? (
      <RowsContent data={table} />
    ) : (
      <ColsContent data={table.schema} />
    );
  return <StyledTableContainer>{content}</StyledTableContainer>;
}

const RowsContent = React.memo(function RowsContent({
  data: { data, schema },
}: {
  data: DataTable;
}) {
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {schema.fields.map(field => (
            <TableCell key={field.name}>
              <Typography variant="body1" whiteSpace="nowrap" fontWeight={500}>
                {field.name}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, rowIndex) => (
          <TableRow key={rowIndex}>
            {schema.fields.map(field => (
              <TableCell key={field.name}>
                <Typography variant="body1" whiteSpace="nowrap">
                  {formatRowValue(row[field.name])}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});

const ColsContent = React.memo(function ColsContent({
  data,
}: {
  data: DataTable['schema'];
}) {
  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body1" whiteSpace="nowrap" fontWeight={500}>
              Column
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1" whiteSpace="nowrap" fontWeight={500}>
              Type
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.fields.map(field => (
          <TableRow key={field.name}>
            <TableCell>
              <Typography variant="body1" whiteSpace="nowrap">
                {field.name}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="body1" whiteSpace="nowrap" textTransform="capitalize">
                {field.type}
              </Typography>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
});

function formatRowValue(v: any): string {
  // we want to be able to show booleans
  return v === null || v === undefined ? '-' : `${v}`;
}

const StyledTableContainer = styled(TableContainer)(({ theme: { spacing } }) => ({
  gridArea: 'content',
  border: `1px solid ${Colors.GREY_30}`,
  borderRadius: spacing(2),
}));
