import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import FilterListIcon from '@mui/icons-material/FilterList';
import HelpIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { onDragAndDropElementCallback } from 'client/app/apps/workflow-builder/lib/dragAndDropElementsHelper';
import { ReleaseQualityIndicator } from 'client/app/apps/workflow-builder/lib/ReleaseQualityIndicator';
import ElementFilter from 'client/app/apps/workflow-builder/panels/elements-list/ElementFilter';
import {
  ElementView,
  filterAndRankElements,
} from 'client/app/apps/workflow-builder/panels/elements-list/filterAndRank';
import TagsPanel, {
  getAvailableTags,
} from 'client/app/apps/workflow-builder/panels/elements-list/TagsPanel';
import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import ElementDetailsDialog from 'client/app/components/ElementPlumber/ElementDetailsDialog';
import { MarkdownQueryHighlighter } from 'client/app/components/MarkdownQueryHighlighter';
import QueryHighlighter from 'client/app/components/QueryHighlighter';
import { ElementSetQuery, ReleaseQualityEnum } from 'client/app/gql';
import { smartSearch } from 'client/app/lib/smartSearch';
import { getElementDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import { getElementShortDescription, getTagLabel } from 'client/app/lib/workflow/format';
import useElementConfigs from 'client/app/lib/workflow/useElementConfigs';
import { ScreenRegistry } from 'client/app/registry';
import { useWorkflowBuilderSelector } from 'client/app/state/WorkflowBuilderStateContext';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { stringToMarkdown } from 'common/lib/markdown';
import { Element } from 'common/types/bundle';
import { ElementConfigurationSpec } from 'common/types/elementConfiguration';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import IconButton from 'common/ui/components/IconButton';
import { logEvent } from 'common/ui/GoogleAnalyticsUtils';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import useDialog from 'common/ui/hooks/useDialog';
import useProgressiveList from 'common/ui/hooks/useProgressiveList';

type GraphQLElementSet = ElementSetQuery['elementSet'];

/** Base props for this component passed down from the WorkflowBuilder. */
export type ElementsListPanelBaseProps = {
  selectedElementID?: string | null;
  onSwitchElementSet: () => void;
  onElementSelect: (elementId: string) => void;
  onDragAndDropElement: onDragAndDropElementCallback;
};

/** Additional props that we receive when rendering this panel in the ControlOverlay. */
type Props = ElementsListPanelBaseProps & {
  className: string;
  onClose: () => void;
  tagsPanelClassName: string;
};

export default React.memo(function ElementsListPanel(props: Props) {
  const [searchQueryRegex, setSearchQueryRegex] = useState<RegExp | null>(null);
  const [searchTermCount, setSearchTermCount] = useState<number>(0);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const classes = useStyles();

  const filterButtonRef = useRef(null);

  const [showTagsPanel, setShowTagsPanel] = useState(false);
  const onTagsPanelClose = useCallback(() => {
    setShowTagsPanel(false);
  }, []);

  const onClickTagsPanelButton = useCallback(() => {
    setShowTagsPanel(!showTagsPanel);
  }, [showTagsPanel]);

  const onTagFilterChange = useCallback(
    (tag: string) => {
      const updatedTags = selectedTags.filter(existingTag => existingTag !== tag);
      setSelectedTags(updatedTags);
    },
    [selectedTags],
  );

  // We need the list ref to scroll it to top when the search changes.
  const listRef = useRef<HTMLDivElement | null>(null);

  const [infoDialog, openInfoDialog] = useDialog(ElementDetailsDialog);
  const handleElementInfoClick = useCallback(
    async (element: Element, event: React.MouseEvent) => {
      event.stopPropagation();
      logEvent('open-element-info', ScreenRegistry.WORKFLOW, element.name);
      await openInfoDialog({
        elementId: element.id,
      });
    },
    [openInfoDialog],
  );

  const isElementConfigDebugModeEnabled = useFeatureToggle(
    'ELEMENT_CONFIGURATION_DEBUG_MODE',
  );

  const elementSet = useWorkflowBuilderSelector(
    state => state.elementSet as GraphQLElementSet,
  );

  const { elements } = elementSet;
  const { elementConfigs } = useElementConfigs(elementSet.id);

  const preprocessedElements = useMemo(() => {
    return elements
      ? elements.map(el =>
          getElementView(el, isElementConfigDebugModeEnabled, elementConfigs),
        )
      : [];
  }, [elementConfigs, elements, isElementConfigDebugModeEnabled]);

  const availableTags = useMemo<string[]>(() => {
    return getAvailableTags(elements);
  }, [elements]);

  // Once the list is rendered, scroll the top if the search query or tag filter is changed
  // so the user can see best results.
  useEffect(() => {
    if (listRef.current) {
      listRef.current.scrollTo(0, 0);
    }
  }, [searchQueryRegex, selectedTags]);

  const updateSearchQuery = useCallback((searchQuery: string) => {
    const { searchTermCount: newTermCount, searchQueryRegex: newQueryRegex } =
      smartSearch(searchQuery);
    setSearchTermCount(newTermCount);
    setSearchQueryRegex(newQueryRegex);
  }, []);

  const filteredElements = useMemo(
    () =>
      filterAndRankElements(
        preprocessedElements,
        searchQueryRegex,
        searchTermCount,
        selectedTags,
      ),
    [preprocessedElements, searchQueryRegex, searchTermCount, selectedTags],
  );

  const renderedElements = useProgressiveList(filteredElements, 20);

  const elementsList = useMemo(
    () =>
      renderedElements.map((elementView, i) => (
        <ElementsListItemWithRef
          {...props}
          key={i}
          elementView={elementView}
          onElementInfoClick={handleElementInfoClick}
          searchQueryRegex={searchQueryRegex}
        />
      )),
    [handleElementInfoClick, props, renderedElements, searchQueryRegex],
  );

  return (
    <>
      <Panel
        className={props.className}
        title="Elements"
        onClose={props.onClose}
        scrollableRef={listRef}
        panelContent="ElementsList"
        filters={
          <div className={classes.filters}>
            <ElementFilter onElementFilterChange={updateSearchQuery} />
            <Button
              className={classes.filterTagButton}
              endIcon={<FilterListIcon />}
              onClick={onClickTagsPanelButton}
              ref={filterButtonRef}
              variant="secondary"
            >
              <Typography color="textPrimary">Filter By Tags</Typography>
            </Button>

            <div>
              {selectedTags.map(tag => {
                return (
                  <TagFilterChip
                    key={tag}
                    onTagFilterChange={onTagFilterChange}
                    tag={tag}
                  />
                );
              })}
            </div>
          </div>
        }
      >
        <Box p={3}>
          {elementsList.length === 0 ? (
            <Typography variant="body1" color="textPrimary">
              No elements found
            </Typography>
          ) : (
            <List disablePadding>{elementsList}</List>
          )}
        </Box>
      </Panel>
      {showTagsPanel && (
        <TagsPanel
          availableTags={availableTags}
          selectedTags={selectedTags}
          onTagFilterChange={setSelectedTags}
          className={props.tagsPanelClassName}
          onClose={onTagsPanelClose}
          alignmentRef={filterButtonRef}
        />
      )}
      {infoDialog}
    </>
  );
});

type TagFilterChipProps = {
  onTagFilterChange: (tag: string) => void;
  tag: string;
};

const TagFilterChip = React.memo(function TagFilterChip(props: TagFilterChipProps) {
  const classes = useStyles();
  const { onTagFilterChange, tag } = props;
  const onDelete = useCallback(() => {
    onTagFilterChange(tag);
  }, [onTagFilterChange, tag]);
  return (
    <Chip
      className={classes.filterChip}
      color="primary"
      label={tag}
      onDelete={onDelete}
      size="small"
    />
  );
});

const ElementsListItemWithRef = React.memo(function ElementsListItemWithRef(
  props: ElementsListItemProps,
) {
  const { elementView, selectedElementID } = props;

  const listItem = <ElementsListItem {...props} key={elementView.element.id} />;

  const isSelected = elementView.element.id === selectedElementID;
  if (!isSelected) {
    return listItem;
  } else {
    return <React.Fragment key={elementView.element.id}>{listItem}</React.Fragment>;
  }
});

type ElementsListItemProps = Omit<ElementsListPanelBaseProps, 'onSwitchElementSet'> & {
  elementView: ElementView;
  searchQueryRegex: RegExp | null;
  onDragAndDropElement: onDragAndDropElementCallback;
  onElementInfoClick: (element: Element, event: React.MouseEvent) => void;
};

const ElementsListItem = React.memo(function ElementsListItem(
  props: ElementsListItemProps,
) {
  const {
    onDragAndDropElement,
    onElementSelect,
    onElementInfoClick,
    elementView,
    searchQueryRegex,
    selectedElementID,
  } = props;
  const classes = useStyles();
  const { element, normalisedName, shortDescription } = elementView;
  const isSelected = element.id === selectedElementID;

  const handleListItemClick = () => {
    logEvent('click-add-element-to-workflow-builder', ScreenRegistry.WORKFLOW);
    return onElementSelect(element.id);
  };

  const handleDragAndDropElement = (e: React.DragEvent<HTMLDivElement>) => {
    return onDragAndDropElement(e, element.id);
  };

  const handleElementInfoClick = useCallback(
    (event: React.MouseEvent) => {
      onElementInfoClick(element, event);
    },
    [element, onElementInfoClick],
  );

  return (
    <ListItem
      button
      className={classes.elementListItem}
      key={element.id}
      onClick={handleListItemClick}
      selected={isSelected}
      onDragStart={handleDragAndDropElement}
      disableRipple
      draggable
    >
      <div className={classes.elementHeader} data-test="elementSidebarName">
        <Typography
          className={classes.elementName}
          variant="subtitle2"
          color="textPrimary"
        >
          <QueryHighlighter text={normalisedName} query={searchQueryRegex} />
        </Typography>
        <div className={classes.additionalInfo}>
          <ReleaseQualityIndicator
            releaseQuality={element.releaseQuality as ReleaseQualityEnum}
          />
          <IconButton
            onClick={handleElementInfoClick}
            size="xsmall"
            icon={<HelpIcon />}
            color="inherit"
          />
        </div>
      </div>
      <div className={classes.elementDescription}>
        <Typography variant="caption" color="textSecondary">
          <MarkdownQueryHighlighter
            markdown={shortDescription}
            query={searchQueryRegex}
          />
        </Typography>
      </div>
      <div className={classes.elementTags}>
        {element.tags.map(tag => (
          <Typography key={tag} className={classes.tagLabel} variant="caption">
            {getTagLabel(tag)}
          </Typography>
        ))}
      </div>
    </ListItem>
  );
});

function getElementView(
  element: Element,
  isElementConfigDebugModeEnabled: boolean,
  elementConfigs?: Record<string, ElementConfigurationSpec | null>,
): ElementView {
  return {
    element,
    shortDescription: stringToMarkdown(
      getElementShortDescription(element, elementConfigs),
    ),
    normalisedName: getElementDisplayName(element, isElementConfigDebugModeEnabled),
  };
}

const useStyles = makeStylesHook(theme => ({
  additionalInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(3),
  },
  elementTags: {
    display: 'flex',
    marginTop: theme.spacing(3),
    gap: theme.spacing(3),
  },
  elementListItem: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: Colors.GREY_10,
    padding: theme.spacing(3),
    borderRadius: '4px',
    '&:not(:last-child)': {
      marginBottom: theme.spacing(3),
    },
  },
  elementHeader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '0.2em',
  },
  elementName: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  elementDescription: {
    padding: theme.spacing(2, 0),
  },
  filters: {
    display: 'flex',
    flexDirection: 'column',
  },
  filterChip: {
    margin: theme.spacing(2),
  },
  filterTagButton: {
    margin: theme.spacing(5, 0, 4, 0),
  },
  tagLabel: {
    color: Colors.BLUE_100,
  },
}));
