import React, { useEffect, useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';

import { useCreateProtocolAndNavigate } from 'client/app/apps/protocols/api/ProtocolsAPI';
import UIErrorBox from 'client/app/components/UIErrorBox';

type Props = {
  id: WorkflowId;
  name: string;
};

export default function CreateProtocolFromWorkflow({ id, name }: Props) {
  const [error, setError] = useState<Error | null>(null);
  const { handleCreateProtocolAndNavigate, loading } = useCreateProtocolAndNavigate();

  useEffect(() => {
    const executeAsync = async () => {
      try {
        await handleCreateProtocolAndNavigate(name, id);
      } catch (e) {
        setError(e);
      }
    };
    void executeAsync();
  }, [handleCreateProtocolAndNavigate, id, name]);

  return (
    <>
      {loading && <LinearProgress />}
      {error && <UIErrorBox>Could not create the protocol: {error.message}</UIErrorBox>}
    </>
  );
}
