import { useMutation } from '@apollo/client';

import { MUTATION_CREATE_LIQUID_POLICY } from 'client/app/api/gql/mutations';
import { QUERY_LIQUID_POLICIES } from 'client/app/api/gql/queries';
import { State } from 'client/app/apps/policy-library/components/PolicyDialogStateContext';
import { buildPolicyInput } from 'client/app/apps/policy-library/components/policyInput.helpers';
import { LiquidPolicyInput } from 'client/app/gql';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useAddPolicy({ onSuccess }: { onSuccess: () => void }) {
  const [createPolicy, { loading }] = useMutation(MUTATION_CREATE_LIQUID_POLICY, {
    refetchQueries: [QUERY_LIQUID_POLICIES],
  });
  const { showSuccess, showError } = useSnackbarManager();
  return {
    loading,
    addPolicy: async (policy: State['policy']) => {
      let input: LiquidPolicyInput;
      try {
        input = buildPolicyInput(policy);
      } catch (error) {
        showError(error.message);
        return;
      }

      await createPolicy({
        variables: {
          input,
        },
        onCompleted() {
          showSuccess(`Successfully created liquid policy "${policy.name.value}"`);
          onSuccess();
        },
        onError(error) {
          showError(error.message);
        },
      });
    },
  };
}
