import { useCallback } from 'react';

import {
  DialogMode,
  usePolicyDialogState,
} from 'client/app/apps/policy-library/components/PolicyDialogStateContext';
import { LiquidPolicy } from 'client/app/gql';

export default function useOpenPolicyEditor(mode: DialogMode) {
  const [_, dispatch] = usePolicyDialogState();

  return useCallback(
    (policy: LiquidPolicy) => {
      dispatch({
        type: 'open_dialog',
        payload: {
          mode,
          policy,
        },
      });
    },
    [dispatch, mode],
  );
}
