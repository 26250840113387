import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import { useProtocolInstanceContext } from 'client/app/apps/protocols/context/ProtocolInstanceProvider';
import { useSimulationContext } from 'client/app/apps/protocols/context/SimulationProvider';
import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import { EditProtocolInstanceHeader } from 'client/app/apps/protocols/EditProtocolInstanceHeader';
import { InputStepList } from 'client/app/apps/protocols/InputStepList';
import {
  InputStepParameters,
  InputStepParametersSkeleton,
  ParameterEditorList,
  ParameterSkeletonList,
} from 'client/app/apps/protocols/InputStepParameters';
import {
  useComplexParameterEditorDialogManager,
  useStepErrors,
} from 'client/app/apps/protocols/lib/utils';
import OutputStepErrors from 'client/app/apps/protocols/OutputStepErrors';
import {
  OutputStepPreview,
  OutputStepPreviewSkeleton,
} from 'client/app/apps/protocols/OutputStepPreview';
import ProtocolInfo from 'client/app/apps/protocols/ProtocolInfo';
import { useCurrentEntity } from 'client/app/components/nav/breadcrumbs/BreadcrumbsEntityContext';
import Colors from 'common/ui/Colors';

export const EditProtocolInstance = () => {
  const {
    loading: workflowLoading,
    config: workflowConfig,
    getSchemaParameters,
  } = useWorkflowContext();
  const { protocolInstance, updateProtocolInput, updateConflictDialog } =
    useProtocolInstanceContext();
  const { simulation } = useSimulationContext();
  const { protocolSteps, selectedStep, workflowSchema } = useStepsContext();
  const stepErrors = useStepErrors(protocolSteps);
  const selectedStepErrors = stepErrors[selectedStep?.id || ''] || [];
  const anyErrorsInStep = selectedStepErrors.some(({ severity }) => severity === 'error');
  const showOutputErrors = simulation?.status === 'FAILED' || anyErrorsInStep;
  const stepName = selectedStep?.displayName || 'Unnamed Step';

  const dialogs = useComplexParameterEditorDialogManager();

  const { showInfo } = useCurrentEntity();
  // Because the `showInfo` state is lifted, it isn't reset when the component changes.
  // So we store and compare against the initial value to ensure we default to not showing
  const [initialShowInfo] = useState(showInfo);

  if (
    !workflowLoading &&
    (!workflowSchema.inputs?.length || !workflowSchema.outputs?.length)
  ) {
    // TODO - Handle this state better in the UI.
    return <p>No inputs or outputs found</p>;
  }

  return (
    <>
      <EditProtocolInstanceHeader />
      <Wrapper>
        <InputStepList />
        <InputsAndOutputsWrapper>
          {workflowLoading ? (
            <>
              <InputStepParametersSkeleton />
              <OutputStepPreviewSkeleton />
            </>
          ) : (
            <>
              <InputStepParameters displayName={stepName}>
                {selectedStep ? (
                  <ParameterEditorList
                    inputParameters={getSchemaParameters(selectedStep.inputs)}
                    workflowConfig={workflowConfig}
                    updateParameter={updateProtocolInput}
                  />
                ) : (
                  <ParameterSkeletonList />
                )}
              </InputStepParameters>
              {showOutputErrors ? (
                <OutputStepErrors
                  simulation={simulation}
                  elementErrors={selectedStepErrors}
                />
              ) : (
                <OutputStepPreview />
              )}
            </>
          )}
        </InputsAndOutputsWrapper>
        {dialogs}
        {updateConflictDialog}
        {showInfo !== initialShowInfo && (
          <ProtocolInfo protocol={protocolInstance.protocol} />
        )}
      </Wrapper>
    </>
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: `
      "list inputsAndOutputs inputsAndOutputs" minmax(400px, 1200px)
      / auto 1fr 1fr`,
  padding: theme.spacing(8),
  gap: theme.spacing(7),
  height: '100%',
  overflow: 'auto',
  backgroundColor: Colors.GREY_10,
  position: 'relative',
}));

const InputsAndOutputsWrapper = styled('div')({
  gridArea: 'inputsAndOutputs',
  display: 'flex',
  overflowX: 'auto',
  zIndex: 1,
});
