import React from 'react';

import ParameterEditorWithHelperText from 'client/app/components/Parameters/ParameterEditor';
import { AdditionalEditorProps } from 'common/elementConfiguration/AdditionalEditorProps';
import {
  ElementInstance,
  Parameter,
  ParameterValue,
  WorkflowConfig,
} from 'common/types/bundle';

type Props = {
  element: ElementInstance;
  parameter: Parameter;
  value: any;
  editorProps?: AdditionalEditorProps;
  workflowConfig: WorkflowConfig;
  onChange: (param: ParameterValue) => void;
  isDisabled?: boolean;
};

export const ProtocolElementParameter = React.memo(
  ({
    element,
    parameter,
    value,
    editorProps,
    workflowConfig,
    onChange,
    isDisabled,
  }: Props) => {
    return (
      // The only dependence on react state is workflow builder state for
      // parameters that make use of element output contexts
      <ParameterEditorWithHelperText
        anthaType={parameter.type}
        parameter={parameter}
        onChange={(value, _) => onChange(value)}
        instanceName={element.name}
        editorType={parameter.configuration?.editor.type}
        editorProps={
          editorProps || parameter.configuration?.editor.additionalProps || undefined
        }
        workflowConfig={workflowConfig}
        placeholder={parameter.configuration?.editor.placeholder}
        value={value}
        isDisabled={isDisabled}
      />
    );
  },
);
