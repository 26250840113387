import React, { FunctionComponent, PropsWithChildren } from 'react';

import { WelcomePage } from 'client/app/apps/Login/WelcomePage';
import { usePermissions } from 'client/app/components/Permissions';

export const WelcomeCheck: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const { permissions } = usePermissions();

  if (permissions.length === 0) {
    return <WelcomePage />;
  }
  return <>{children}</>;
};
