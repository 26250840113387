import React, { useRef, useState } from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import ProtocolsList from 'client/app/apps/protocols/ProtocolsList';
import CategoriesList from 'client/app/components/ExampleWorkflows/CategoriesList';
import { HeaderWithDescription } from 'client/app/components/ExampleWorkflows/HeaderWithDescription';
import { useUserProfile } from 'client/app/hooks/useUserProfile';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useStateWithURLParams } from 'common/ui/hooks/useStateWithURLParams';

export default function ProtocolsScreen() {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const { navigate } = useNavigation();
  const currentUser = useUserProfile();
  const [searchQuery, setSearchQuery] = useStateWithURLParams({
    paramName: 'search',
    paramType: 'string',
    defaultValue: '',
  });

  const [tagQuery, setTagQuery] = useStateWithURLParams({
    paramName: 'tag',
    paramType: 'string',
    defaultValue: '',
  });

  const [tabIndex, setTabIndex] = useState(0);

  const handleSetTabIndex = (_: React.SyntheticEvent, index: number) => {
    setTabIndex(index);
  };

  const handleOnProtocolClick = (id: ProtocolId, version: ProtocolVersion) => {
    navigate(protocolsRoutes.editProtocol, {
      id,
      version,
    });
  };

  return (
    <ExperimentsNavigation title="Protocols">
      <HeaderWithDescription
        title="Protocols"
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <CategoriesAndDataWrapper>
        <CategoriesList
          selectedTag={tagQuery}
          onSelectTag={setTagQuery}
          entityClassName="Protocol"
        />
        <Box sx={{ overflow: 'hidden' }}>
          <Tabs value={tabIndex} onChange={handleSetTabIndex}>
            <Tab label="Public" />
            <Tab label="Authored by me" />
            <Tab label="In progress" />
          </Tabs>
          <ContainerWithIntersectionBar
            scrollableRef={scrollableContainerRef}
            noHeader
            content={
              <ListWrapper>
                <ProtocolsList
                  hidden={tabIndex !== 0}
                  currentUserId={currentUser?.id}
                  scrollableContainerRef={scrollableContainerRef}
                  searchQuery={searchQuery}
                  tagQuery={tagQuery}
                  onProtocolClick={handleOnProtocolClick}
                  queryVariables={{
                    isLatest: true,
                    isDeleted: false,
                    isPublic: true,
                  }}
                />
                <ProtocolsList
                  hidden={tabIndex !== 1}
                  currentUserId={currentUser?.id}
                  scrollableContainerRef={scrollableContainerRef}
                  searchQuery={searchQuery}
                  tagQuery={tagQuery}
                  onProtocolClick={handleOnProtocolClick}
                  queryVariables={{
                    isLatest: true,
                    isDeleted: false,
                    isPublished: true,
                    userId: currentUser?.id,
                  }}
                />
                <ProtocolsList
                  hidden={tabIndex !== 2}
                  currentUserId={currentUser?.id}
                  scrollableContainerRef={scrollableContainerRef}
                  searchQuery={searchQuery}
                  tagQuery={tagQuery}
                  onProtocolClick={handleOnProtocolClick}
                  queryVariables={{
                    isPublished: false,
                    isDeleted: false,
                    userId: currentUser?.id,
                  }}
                />
              </ListWrapper>
            }
            dense
          />
        </Box>
      </CategoriesAndDataWrapper>
    </ExperimentsNavigation>
  );
}

const CategoriesAndDataWrapper = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'minmax(180px, 0.75fr) 4fr',
  columnGap: theme.spacing(8),
  overflow: 'hidden',
}));

const ListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(6),
}));
