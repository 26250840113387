import React from 'react';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import { ProtocolItem } from 'client/app/apps/protocols/ProtocolElementInstancePanel/ProtocolItem';
import { pluralizeWord } from 'common/lib/format';
import { Parameter } from 'common/types/bundle';
import { ProtocolStep } from 'common/types/Protocol';

type ProtocolItemState = {
  checked: boolean;
  disabled: boolean;
  message: string;
};

function ProtocolInputMode(
  selections: Record<string, ProtocolStep[]>,
  activeStepId: string,
  paramName: string,
): ProtocolItemState {
  // the step that this parameter is selected in, or undefined if
  // not selected
  const selectedInSteps = selections[paramName];
  const checked = !!selectedInSteps?.some(({ id }) => id === activeStepId);
  const disabled = selectedInSteps && !checked;

  let message = 'Parameter is not in protocol';
  if (disabled) {
    message = `Selected in '${selectedInSteps[0].displayName}'`;
  }
  if (checked) {
    message = 'Selected in this step';
  }

  return { checked, disabled, message };
}

function ProtocolOutputMode(
  selections: Record<string, ProtocolStep[]>,
  activeStepId: string,
  paramName: string,
): ProtocolItemState {
  // the step that this parameter is selected in, or undefined if
  // not selected
  const selectedInSteps = selections[paramName];
  const checked = !!selectedInSteps?.some(({ id }) => id === activeStepId);
  const disabled = false;
  const otherSteps = selectedInSteps?.filter(({ id }) => id !== activeStepId).length ?? 0;

  let message = 'Output is not in protocol';
  if (checked) {
    if (otherSteps > 0) {
      message = `Selected in this and ${otherSteps} other ${pluralizeWord(
        otherSteps,
        'step',
      )}`;
    } else {
      message = 'Selected in this step';
    }
  } else if (otherSteps > 0) {
    message = `Selected in ${otherSteps} other ${pluralizeWord(otherSteps, 'step')}`;
  }
  return { checked, disabled, message };
}

type Props = {
  elementId: string;
  parameters: { parameter: Parameter; value?: any }[];
  selections: Record<string, ProtocolStep[]>;
  activeStepId: string;
  mode: 'input' | 'output';
  onChange: (param: Parameter, value: any, checked: boolean) => void;
};

export default function ProtocolItemList({
  parameters,
  elementId,
  selections,
  activeStepId,
  mode,
  onChange,
}: Props) {
  return (
    <StyledStack>
      {parameters.map(({ parameter, value }) => {
        const modeFn = mode === 'input' ? ProtocolInputMode : ProtocolOutputMode;
        const { checked, disabled, message } = modeFn(
          selections,
          activeStepId,
          parameter.name,
        );

        return (
          <ProtocolItem
            key={parameter.name}
            elementId={elementId}
            parameter={parameter}
            checked={checked}
            disabled={disabled}
            message={message}
            onToggle={checked => onChange(parameter, value, checked)}
          />
        );
      })}
    </StyledStack>
  );
}

const StyledStack = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(5),
  padding: spacing(0, 2),
}));
