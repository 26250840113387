import React from 'react';

import { useQuery } from '@apollo/client';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { QUERY_LIQUID_POLICIES } from 'client/app/api/gql/queries';
import { usePolicyDialogState } from 'client/app/apps/policy-library/components/PolicyDialogStateContext';
import { makeValidateName } from 'client/app/apps/policy-library/components/validation';
import { LiquidPolicy } from 'client/app/gql';
import useDebounce from 'common/ui/hooks/useDebounce';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

export default function PropertiesTab() {
  const [{ policy, dialog }, dispatch] = usePolicyDialogState();
  const handleNameChange = usePolicyNameChange();
  const handleDescriptionChange = useTextFieldChange(input => {
    dispatch({
      type: 'change_policy_description',
      payload: input,
    });
    dispatch({ type: 'validate_policy_description' });
  });

  return (
    <Stack gap={6} alignItems="stretch">
      <Stack direction="row" alignItems="baseline" gap={4}>
        <LabelInputRequired variant="body1">Policy Name</LabelInputRequired>
        <NameInput
          variant="standard"
          placeholder="Name your policy"
          value={policy.name.value}
          onChange={handleNameChange}
          fullWidth
          error={!!policy.name.error}
          // Cannot change Liquid Policy name as it must be the same for all versions
          disabled={dialog.mode === 'edit'}
          helperText={
            policy.name.error && (
              <Typography variant="caption" whiteSpace="nowrap">
                {policy.name.error}
              </Typography>
            )
          }
          autoComplete="off"
        />
      </Stack>
      <Stack gap={3}>
        <LabelInputRequired variant="body1">Policy Description</LabelInputRequired>
        <TextField
          variant="outlined"
          placeholder="Describe your policy"
          value={policy.description.value}
          onChange={handleDescriptionChange}
          fullWidth
          multiline
          rows={5}
          error={!!policy.description.error}
          helperText={
            policy.description.error && (
              <Typography variant="caption">{policy.description.error}</Typography>
            )
          }
          autoComplete="off"
        />
      </Stack>
    </Stack>
  );
}

function usePolicyNameChange() {
  const [_, dispatch] = usePolicyDialogState();

  const { data } = useQuery<{ liquidPolicies: LiquidPolicy[] }>(QUERY_LIQUID_POLICIES, {
    fetchPolicy: 'cache-only',
  });
  const validate = makeValidateName({
    liquidPolicies: data?.liquidPolicies,
  });

  const debouncedValidate = useDebounce((newName: string) => {
    const error = validate(newName);
    dispatch({
      type: 'validate_policy_name',
      payload: error,
    });
  }, 500);

  return useTextFieldChange((newName: string) => {
    dispatch({
      type: 'change_policy_name',
      payload: newName,
    });
    debouncedValidate(newName);
  });
}

const LabelInputRequired = styled(Typography)(({ theme }) => ({
  '&:after': {
    content: '"*"',
    color: theme.palette.error.main,
    marginLeft: theme.spacing(1),
  },
}));

const NameInput = styled(TextField)({
  width: 220,
});
