import React, { PropsWithChildren, useCallback } from 'react';

import Input from '@mui/material/Input';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { SchemaParameter } from 'client/app/apps/protocols/context/WorkflowProvider';
import EditableName, { Name } from 'client/app/apps/protocols/EditableName';
import { ProtocolElementParameter } from 'client/app/apps/protocols/ProtocolElementParameter';
import { Markdown } from 'common/lib/markdown';
import { ParameterValue, WorkflowConfig } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { DraggableList } from 'common/ui/components/DragDrop/DraggableList';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type StepParametersProps = {
  displayName: string;
} & PropsWithChildren;

export const InputStepParameters = ({ displayName, children }: StepParametersProps) => {
  return (
    <Wrapper>
      <InputStepName variant="h4">{displayName}</InputStepName>
      <ContainerWithIntersectionBar dense noHeader content={children} />
    </Wrapper>
  );
};

export function InputStepParametersSkeleton() {
  return (
    <Wrapper>
      <InputStepName variant="h4">Loading step...</InputStepName>
      <ParameterSkeletonList />
    </Wrapper>
  );
}

export function ParameterSkeletonList() {
  return (
    <Stack spacing={5}>
      <Skeleton sx={{ mb: 3 }} variant="rounded" width="100%" height={20} />
      <Skeleton sx={{ mb: 5 }} variant="rounded" width="100%" height={50} />
      <Skeleton sx={{ mb: 3 }} variant="rounded" width="100%" height={20} />
      <Skeleton variant="rounded" width="100%" height={50} />
    </Stack>
  );
}

type EditorProps = {
  inputParameters: SchemaParameter[];
  workflowConfig: WorkflowConfig;
  updateParameter: (schemaInputId: string, value: any) => void;
};

export function ParameterEditorList({
  inputParameters,
  workflowConfig,
  updateParameter,
}: EditorProps) {
  const handleParameterChange = useCallback(
    (schemaInputId: string) => (value: ParameterValue) =>
      updateParameter(schemaInputId, value),
    [updateParameter],
  );

  return (
    <Stack spacing={5}>
      {inputParameters.map(
        ({ id: inputId, parameter, parameterValue, editorProps, element }) => {
          const { displayName = 'Unnamed Parameter', displayDescription } =
            parameter.configuration || {};
          return (
            <Stack spacing={4} key={inputId}>
              <Name sx={{ fontWeight: 500, fontSize: '14px' }}>{displayName}</Name>
              {displayDescription && (
                <ParameterDescription>{displayDescription}</ParameterDescription>
              )}
              <ProtocolElementParameter
                element={element}
                parameter={parameter}
                value={parameterValue}
                editorProps={editorProps}
                onChange={handleParameterChange(inputId)}
                workflowConfig={workflowConfig}
              />
            </Stack>
          );
        },
      )}
    </Stack>
  );
}

type DescriberProps = {
  inputParameters: SchemaParameter[];
  workflowConfig: WorkflowConfig;
  updateDescription: (
    stepInputIndex: number,
    opts: { name?: string; description?: Markdown },
  ) => void;
  updateOrder: (orderedInputIds: string[]) => void;
};

export function ParameterDescriberList({
  inputParameters,
  workflowConfig,
  updateDescription,
  updateOrder,
}: DescriberProps) {
  const handleChangeOrder = (reordered: SchemaParameter[]) => {
    updateOrder(reordered.map(({ id }) => id));
  };

  return (
    <DraggableList
      items={inputParameters}
      getIdFromItem={({ id }) => id}
      onChangeOrder={handleChangeOrder}
      renderItem={(
        { id: inputId, parameter, parameterValue, editorProps, element },
        dragProps,
        index,
      ) => {
        const { displayName = '', displayDescription = '' } =
          parameter.configuration || {};
        return (
          <Stack spacing={4} key={inputId}>
            <Stack
              direction="row"
              spacing={4}
              paddingTop={3}
              justifyContent="flex-start"
              alignItems="center"
            >
              {inputParameters.length > 1 && (
                <StyledDragIcon {...dragProps.attributes} {...dragProps.listeners}>
                  {dragProps.dragIcon}
                </StyledDragIcon>
              )}
              <EditableName
                sx={{ fontWeight: 500, fontSize: '14px' }}
                name={displayName}
                onSave={name => updateDescription(index, { name })}
                canDelete={false}
                onDelete={() => {}}
              />
            </Stack>
            <EditableParameterDescription
              multiline
              disableUnderline
              value={displayDescription}
              placeholder="+ Add description"
              onChange={e =>
                updateDescription(index, {
                  description: e.target.value as Markdown,
                })
              }
            />
            <ProtocolElementParameter
              element={element}
              parameter={parameter}
              value={parameterValue}
              editorProps={editorProps}
              onChange={() => {}}
              workflowConfig={workflowConfig}
              isDisabled
            />
          </Stack>
        );
      }}
    />
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '376px',
  maxWidth: '376px',
  padding: theme.spacing(5),
  gap: theme.spacing(5),
  borderRadius: theme.spacing(3, 0, 0, 3),
  border: `1px solid ${Colors.GREY_30}`,
  backgroundColor: 'white',
}));

const InputStepName = styled(TypographyWithTooltip)(() => ({
  fontWeight: 600,
}));

const EditableParameterDescription = styled(Input)(({ theme }) => ({
  '&.Mui-focused, &:hover': {
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: '4px',
    padding: theme.spacing(3),
    fontSize: '14px',
  },
  fontWeight: 300,
}));

const ParameterDescription = styled(Typography)({
  whiteSpace: 'pre-line',
  fontWeight: 300,
  fontSize: '14px',
});

const StyledDragIcon = styled('span')(({ theme }) => ({
  '& > svg': {
    color: theme.palette.text.primary,
  },
}));
