import React, { useCallback } from 'react';

import { useQuery } from '@apollo/client';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

import { QUERY_PROTOCOLS } from 'client/app/api/gql/queries';
import DataComponent from 'client/app/apps/protocols/ProtocolsListDataComponent';
import NoDataComponent from 'client/app/components/ExampleWorkflows/ExampleWorkflowsList/ExampleWorkflowsListNoDataComponent';
import usePagination from 'client/app/hooks/usePagination';
import { PageInfo } from 'common/server/graphql/pagination';
import { EntityCardSkeletonList } from 'common/ui/components/EntityCard';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';

type QueryVariables = {
  isLatest?: boolean;
  isDeleted?: boolean;
  isPublic?: boolean;
  isPublished?: boolean;
  userId?: string;
};

type Props = {
  hidden: boolean;
  currentUserId: string | undefined;
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
  searchQuery?: string;
  tagQuery?: string;
  onProtocolClick: (id: ProtocolId, version: ProtocolVersion) => void;
  queryVariables: QueryVariables;
};

const ProtocolsList = ({
  hidden,
  currentUserId,
  scrollableContainerRef,
  searchQuery,
  tagQuery,
  onProtocolClick,
  queryVariables,
}: Props) => {
  const variables = {
    search: searchQuery ?? undefined,
    tagName: tagQuery ?? undefined,
    ...queryVariables,
  };

  const protocolsQuery = useQuery(QUERY_PROTOCOLS, {
    variables,
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const pageInfo = protocolsQuery.data?.protocols.pageInfo as PageInfo | undefined;

  const hasNextPage = usePagination({
    entity: 'protocols',
    pageInfo,
    fetchMore: protocolsQuery.fetchMore,
    dependencies: [],
    scrollableRef: scrollableContainerRef,
    isInitialLoading: protocolsQuery.loading,
    variables: {},
  });

  const handleOnProtocolDetailsClick = useCallback(
    (id: ProtocolId, version: ProtocolVersion) => {
      onProtocolClick?.(id, version);
    },
    [onProtocolClick],
  );

  return (
    <Box hidden={hidden}>
      <RenderQuery
        query={protocolsQuery}
        renderData={DataComponent}
        renderNoData={NoDataComponent}
        additionalDataProps={{
          onProtocolDetailsClick: handleOnProtocolDetailsClick,
          currentUserId,
        }}
        loadingComponent={EntityCardSkeletonList}
        additionalNoDataProps={{ searchQuery }}
        emptyCondition={data => data.protocols.items.length === 0}
      />
      {hasNextPage && (
        <LoadingContainer>
          <CircularProgress size={24} />
        </LoadingContainer>
      )}
    </Box>
  );
};

const LoadingContainer = styled('div')(() => ({
  width: 'fit-content',
  margin: '0 auto',
}));

export default ProtocolsList;
