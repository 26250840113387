import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';

import {
  ProtocolUpdate,
  useUpdateProtocol,
} from 'client/app/apps/protocols/api/ProtocolsAPI';
import { useUpdateEntity } from 'client/app/apps/protocols/lib/utils';
import { ProtocolQuery, Simulation } from 'client/app/gql';
import { Markdown } from 'common/lib/markdown';
import { ErrorCodes } from 'common/types/errorCodes';

type ProtocolContextType = {
  name: string;
  displayDescription: Markdown;
  shortDescription: string;
  updateProtocol: (values: ProtocolUpdate) => void;
  conflictDialog: JSX.Element | null;
  exampleSimulation: Pick<
    Simulation,
    'status' | 'id' | 'actionsLayoutFiletreeLink'
  > | null;
};

export const ProtocolContext = createContext<ProtocolContextType | undefined>(undefined);

type ProtocolProviderProps = {
  entity: NonNullable<ProtocolQuery['protocol']['entity']>;
  version: ProtocolVersion;
} & PropsWithChildren;

export const useProtocolContext = () => {
  const context = useContext(ProtocolContext);

  if (context === undefined) {
    throw new Error('useProtocolContext must be used within a ProtocolProvider');
  }

  return context;
};

export const ProtocolProvider: FC<ProtocolProviderProps> = ({
  entity,
  version,
  children,
}) => {
  const {
    id: protocolId,
    name,
    editVersion,
    shortDescription,
    exampleSimulation,
    protocol: { displayDescription },
  } = entity;

  const { handleUpdateProtocol } = useUpdateProtocol(protocolId, version);
  const [protocolUpdate, setProtocolUpdate] = useState<ProtocolUpdate>({});

  const { conflictDialog, setUpdateRequired } = useUpdateEntity({
    entityType: 'protocol',
    editVersion: editVersion,
    conflictCode: ErrorCodes.PROTOCOL_EDIT_CONFLICT,
    handleUpdate: useCallback(
      async (editVersion: number) => handleUpdateProtocol(editVersion, protocolUpdate),
      [handleUpdateProtocol, protocolUpdate],
    ),
  });

  const updateProtocol = useCallback(
    (values: ProtocolUpdate) => {
      setProtocolUpdate(values);
      setUpdateRequired(true);
    },
    [setUpdateRequired],
  );

  const state = {
    name,
    shortDescription,
    displayDescription,
    updateProtocol,
    conflictDialog,
    exampleSimulation,
  };

  return <ProtocolContext.Provider value={state}>{children}</ProtocolContext.Provider>;
};
