import React from 'react';

import '@mdxeditor/editor/style.css';

import {
  type MDXEditorProps,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  headingsPlugin,
  InsertTable,
  linkDialogPlugin,
  linkPlugin,
  listsPlugin,
  ListsToggle,
  markdownShortcutPlugin,
  MDXEditor,
  quotePlugin,
  Separator,
  tablePlugin,
  thematicBreakPlugin,
  toolbarPlugin,
  UndoRedo,
} from '@mdxeditor/editor';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/styles';

export default function MarkdownEditor({ ...props }: MDXEditorProps) {
  return (
    <StyledPaper variant="outlined">
      <MDXEditor
        plugins={[
          toolbarPlugin({
            toolbarClassName: 'my-classname',
            toolbarContents: () => (
              <>
                {' '}
                <UndoRedo />
                <Separator />
                <BlockTypeSelect />
                <Separator />
                <BoldItalicUnderlineToggles />
                <Separator />
                <CreateLink />
                <Separator />
                <ListsToggle options={['bullet', 'number']} />
                <Separator />
                <InsertTable />
              </>
            ),
          }),
          headingsPlugin(),
          listsPlugin(),
          quotePlugin(),
          thematicBreakPlugin(),
          markdownShortcutPlugin(),
          tablePlugin(),
          linkPlugin(),
          linkDialogPlugin(),
        ]}
        {...props}
      />
    </StyledPaper>
  );
}

const StyledPaper = styled(Paper)({ borderRadius: 4 });
