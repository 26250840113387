import React, { useCallback, useMemo, useRef } from 'react';

import { useQuery } from '@apollo/client';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

import { QUERY_UNIQUE_TAGS } from 'client/app/api/gql/queries';
import { EXAMPLE_WORKFLOWS_ALL_TAG_NAME } from 'client/app/components/ExampleWorkflows/exampleWorkflowsUtils';
import { UniqueTagsQuery } from 'client/app/gql';
import { isDefined } from 'common/lib/data';
import { alphanumericCompare } from 'common/lib/strings';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { RenderQuery } from 'common/ui/components/RenderQuery/RenderQuery';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type CategoriesListProps = {
  selectedTag: string | undefined;
  entityClassName: string;
  onSelectTag: (tag: string) => void;
};

/**
 * Renders out a list of categories as buttons that can be selected.
 * The categories will relate to those present on existing example
 * workflows (i.e. the 'tags' of example workflows).
 */
export default function CategoriesList(props: CategoriesListProps) {
  const { selectedTag, onSelectTag, entityClassName } = props;
  const exampleWorkflowsTagsQuery = useQuery(QUERY_UNIQUE_TAGS, {
    variables: {
      entityClassName,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  return (
    <RenderQuery
      query={exampleWorkflowsTagsQuery}
      renderData={CategoriesListDataComponent}
      additionalDataProps={{ selectedTag, onSelectTag }}
      renderNoData={CategoriesListNoDataComponent}
      loadingComponent={CategoriesListItemsSkeletons}
    />
  );
}

type CategoriesListItemProps = {
  name: string;
  selected?: boolean;
  onClick?: () => void;
};

const CategoriesListItem = (props: CategoriesListItemProps) => {
  const { name, onClick, selected } = props;
  return (
    <CategoriesListItemButton disableRipple dense selected={selected} onClick={onClick}>
      <TypographyWithTooltip variant="subtitle2">{name}</TypographyWithTooltip>
    </CategoriesListItemButton>
  );
};

type CategoriesListDataComponentProps = {
  data: UniqueTagsQuery;
  selectedTag: string | undefined;
  onSelectTag: (tag: string) => void;
};

function CategoriesListDataComponent(props: CategoriesListDataComponentProps) {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const { data, onSelectTag, selectedTag } = props;
  const handleSelectTag = useCallback(
    (tag: string) => {
      onSelectTag(tag === EXAMPLE_WORKFLOWS_ALL_TAG_NAME ? '' : tag);
    },
    [onSelectTag],
  );

  const tagsListItems = useMemo(() => {
    const allTags: JSX.Element[] = [];
    data.uniqueTags
      ?.filter(isDefined)
      .sort(alphanumericCompare)
      .forEach(tagName => {
        allTags.push(
          <CategoriesListItem
            key={tagName}
            name={tagName}
            onClick={() => handleSelectTag(tagName)}
            selected={selectedTag === tagName}
          />,
        );
      });
    allTags.unshift(
      <CategoriesListItem
        key={EXAMPLE_WORKFLOWS_ALL_TAG_NAME}
        name={EXAMPLE_WORKFLOWS_ALL_TAG_NAME}
        onClick={() => handleSelectTag(EXAMPLE_WORKFLOWS_ALL_TAG_NAME)}
        selected={selectedTag === EXAMPLE_WORKFLOWS_ALL_TAG_NAME || !selectedTag}
      />,
    );
    return allTags;
  }, [data.uniqueTags, handleSelectTag, selectedTag]);

  return (
    <ContainerWithIntersectionBar
      scrollableRef={scrollableContainerRef}
      dense
      noHeader
      content={<CategoriesListItems>{tagsListItems}</CategoriesListItems>}
    />
  );
}

function CategoriesListNoDataComponent() {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  return (
    <ContainerWithIntersectionBar
      scrollableRef={scrollableContainerRef}
      dense
      noHeader
      content={
        <CategoriesListItems>
          <CategoriesListItem
            key={EXAMPLE_WORKFLOWS_ALL_TAG_NAME}
            name={EXAMPLE_WORKFLOWS_ALL_TAG_NAME}
            selected
          />
        </CategoriesListItems>
      }
    />
  );
}

function CategoriesListItemsSkeletons() {
  return (
    <CategoriesListItems>
      <Skeleton>
        <CategoriesListItem name="Skeleton1" />
      </Skeleton>
      <Skeleton>
        <CategoriesListItem name="Skeleton2Long" />
      </Skeleton>
      <Skeleton>
        <CategoriesListItem name="Skeleton2" />
      </Skeleton>
    </CategoriesListItems>
  );
}

const CategoriesListItems = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

const CategoriesListItemButton = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(2),
  color: theme.palette.text.secondary,
  background: 'none',
  '&:hover': {
    background: 'none',
  },
  '&.Mui-selected': {
    background: 'none',
    color: theme.palette.primary.main,
    '&:hover': {
      background: 'none',
    },
  },
}));
