import React from 'react';

import ArrowBackSharpIcon from '@mui/icons-material/ArrowBackSharp';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Collapse, { collapseClasses } from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import {
  useProtocolsParamState,
  useStepErrors,
} from 'client/app/apps/protocols/lib/utils';
import { ElementErorrSeverity } from 'common/types/bundle';
import { ProtocolStep } from 'common/types/Protocol';
import Colors from 'common/ui/Colors';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import { DraggableList } from 'common/ui/components/DragDrop/DraggableList';
import IconButton from 'common/ui/components/IconButton';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  alwaysExpandList?: boolean;
  isDraggable?: boolean;
};

export const InputStepList = ({ alwaysExpandList, isDraggable }: Props) => {
  const { protocolSteps } = useStepsContext();
  const { expandInputList, handleSetExpandInputList } =
    useProtocolsParamState(protocolSteps);

  return (
    <Wrapper>
      {!alwaysExpandList && (
        <StyledIconButton
          icon={expandInputList ? <ArrowBackSharpIcon /> : <MenuRoundedIcon />}
          size="xsmall"
          color="default"
          onClick={() => handleSetExpandInputList()}
        />
      )}
      <StyledCollapse orientation="horizontal" in={expandInputList || alwaysExpandList}>
        <Typography color="textSecondary" variant="body2">
          STEPS ({protocolSteps.length})
        </Typography>
        <ContainerWithIntersectionBar
          noHeader
          dense
          content={isDraggable ? <DraggableStepsList /> : <StepsList />}
        />
      </StyledCollapse>
    </Wrapper>
  );
};

const StepsList = () => {
  const { protocolSteps, selectedStep, handleSelectStep } = useStepsContext();
  const stepErrors = useStepErrors(protocolSteps);

  const iconForStepError = (stepId: string) => {
    const errors = stepErrors[stepId];
    if (!errors || errors.length === 0) {
      return null;
    }
    const isAtLeastOneErrorSeverity = errors.some(err => err.severity === 'error');
    return (
      <StyledInfoOutlinedIcon
        severity={isAtLeastOneErrorSeverity ? 'error' : 'warning'}
      />
    );
  };

  return (
    <StyledList dense>
      {protocolSteps.map(step => (
        <StepItem
          key={step.id}
          step={step}
          active={step.id === selectedStep?.id}
          onClick={() => handleSelectStep(step)}
          icon={iconForStepError(step.id)}
        />
      ))}
    </StyledList>
  );
};

const DraggableStepsList = () => {
  const { protocolSteps, reorderSteps, selectedStep, handleSelectStep } =
    useStepsContext();

  return (
    <DraggableList
      items={protocolSteps}
      getIdFromItem={({ id }) => id}
      onChangeOrder={reorderedItems => reorderSteps(reorderedItems)}
      renderItem={(step, dragProps) => (
        <StepItem
          key={step.id}
          step={step}
          active={step.id === selectedStep?.id}
          onClick={() => handleSelectStep(step)}
          icon={
            <StyledDragIcon {...dragProps.attributes} {...dragProps.listeners}>
              {dragProps.dragIcon}
            </StyledDragIcon>
          }
          leftAlignIcon
        />
      )}
    />
  );
};

const StepItem = ({
  step,
  active,
  onClick,
  icon,
  leftAlignIcon,
}: {
  step: ProtocolStep;
  active: boolean;
  onClick: () => void;
  icon: JSX.Element | null;
  leftAlignIcon?: boolean;
}) => {
  return (
    <StyledListItem>
      <StyledListItemButton
        isLeftToRight={leftAlignIcon === true}
        active={active}
        onClick={onClick}
      >
        {icon}
        <ListItemText disableTypography>
          <TypographyWithTooltip variant="body2">
            {step.displayName}
          </TypographyWithTooltip>
        </ListItemText>
      </StyledListItemButton>
    </StyledListItem>
  );
};

const StyledListItem = styled(ListItem)({
  paddingLeft: 0,
  paddingRight: 0,
  minWidth: '200px',
  maxWidth: '200px',
});

const StyledList = styled(List)({
  paddingTop: 0,
});

const StyledListItemButton = styled(ListItemButton, {
  shouldForwardProp: prop => prop !== 'active',
})<{ isLeftToRight: boolean; active: boolean }>(({ isLeftToRight, active, theme }) => ({
  display: 'flex',
  gap: theme.spacing(3),
  justifyContent: 'space-between',
  flexDirection: isLeftToRight ? 'row' : 'row-reverse',
  borderRadius: theme.spacing(2),
  borderLeft: `4px solid ${active ? theme.palette.primary.main : 'transparent'}`,
  backgroundColor: active ? Colors.BLUE_5 : 'default',
  '&:hover, &:focus': {
    backgroundColor: active ? Colors.BLUE_5 : 'default',
  },
}));

const Wrapper = styled('div')(({ theme }) => ({
  gridArea: 'list',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(5),
}));

const StyledCollapse = styled(Collapse)(({ theme }) => ({
  [`& .${collapseClasses.wrapperInner}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
}));

const StyledDragIcon = styled('span')(({ theme }) => ({
  '& > svg': {
    color: theme.palette.text.primary,
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  '& > svg': {
    color: theme.palette.text.primary,
  },
}));

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon, {
  shouldForwardProp: prop => prop !== 'severity',
})<{ severity: ElementErorrSeverity }>(({ severity, theme }) => ({
  color: severity === 'error' ? theme.palette.error.main : theme.palette.warning.dark,
  height: '20px',
  width: '20px',
}));
