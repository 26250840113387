import { useCallback } from 'react';

import { useMutation } from '@apollo/client';

import { MUTATION_DELETE_LIQUID_POLICY } from 'client/app/api/gql/mutations';
import { QUERY_LIQUID_POLICIES } from 'client/app/api/gql/queries';
import { LiquidPolicy } from 'client/app/gql';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';

export default function useDeletePolicy() {
  const { showSuccess, showError } = useSnackbarManager();
  const [callDeletePolicy] = useMutation(MUTATION_DELETE_LIQUID_POLICY);
  return useCallback(
    async (policy: LiquidPolicy) => {
      await callDeletePolicy({
        variables: {
          id: policy.id,
        },
        refetchQueries: [QUERY_LIQUID_POLICIES],
        onCompleted() {
          showSuccess(`Successfully deleted liquid policy "${policy.name}"`);
        },
        onError(error) {
          showError(error.message);
        },
      });
    },
    [callDeletePolicy, showError, showSuccess],
  );
}
