import React from 'react';

import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import { tabClasses } from '@mui/material/Tab';

import ProtocolItemList from 'client/app/apps/protocols/ProtocolElementInstancePanel/ProtocolItemList';
import { useElementNamePopover } from 'client/app/apps/workflow-builder/panels/element-instance-panel/hooks';
import Panel from 'client/app/apps/workflow-builder/panels/Panel';
import { ElementDetailsTabs } from 'client/app/components/ElementDetails/ElementDetails';
import { hasOutputVisualisation } from 'client/app/components/ElementPlumber/ElementOutputs/helpers';
import { Parameter } from 'common/types/bundle';
import { ProtocolStep } from 'common/types/Protocol';
import {
  byElementInstanceId,
  getElementParameterName,
  Schema,
} from 'common/types/schema';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';

type Props = {
  schema?: Schema;
  protocolSteps: ProtocolStep[];
  activeStepId: string;
  elementInstanceId: string;
  elementInstanceName: string;
  inputs: { parameter: Parameter; value: any }[];
  outputs: readonly Parameter[];
  onInputsChange: (param: Parameter, value: any, checked: boolean) => void;
  onOutputsChange: (param: Parameter, checked: boolean) => void;
  activeTab: ElementDetailsTabs;
  setActiveTab?: (tab: ElementDetailsTabs) => void;
  onClose: () => void;
};

const ProtocolElementInstancePanel = ({
  schema,
  protocolSteps,
  activeStepId,
  elementInstanceId,
  elementInstanceName,
  inputs,
  outputs,
  onInputsChange,
  onOutputsChange,
  activeTab,
  setActiveTab,
  onClose,
}: Props) => {
  const { elementNamePopover, ...elementNamePopoverEvents } =
    useElementNamePopover(elementInstanceName);

  const selectedInputs: Record<string, ProtocolStep[]> = {};
  const inputsForElement =
    schema?.inputs?.filter(byElementInstanceId(elementInstanceId)) ?? [];
  protocolSteps.forEach(step => {
    step.inputs.forEach(protocolInput => {
      const schemaInput = inputsForElement.find(v => v.id === protocolInput.id);
      if (schemaInput) {
        const pName = getElementParameterName(schemaInput.path);
        if (!pName) {
          throw 'failed to get parameter name';
        }
        if (!selectedInputs[pName]) {
          selectedInputs[pName] = [];
        }
        selectedInputs[pName].push(step);
      }
    });
  });

  const selectedOutputs: Record<string, ProtocolStep[]> = {};
  const outputForElement =
    schema?.outputs?.filter(byElementInstanceId(elementInstanceId)) ?? [];
  protocolSteps.forEach(step => {
    step.outputs.forEach(protocolOutput => {
      const schemaOutput = outputForElement.find(v => v.id === protocolOutput.id);
      if (schemaOutput) {
        const pName = getElementParameterName(schemaOutput.path);
        if (!pName) {
          throw 'failed to get parameter name';
        }
        if (!selectedOutputs[pName]) {
          selectedOutputs[pName] = [];
        }
        selectedOutputs[pName].push(step);
      }
    });
  });

  const inputsContent = (
    <ProtocolItemList
      elementId={elementInstanceId}
      parameters={inputs}
      selections={selectedInputs}
      activeStepId={activeStepId}
      mode="input"
      onChange={onInputsChange}
    />
  );
  const outputsContent = (
    <ProtocolItemList
      elementId={elementInstanceId}
      parameters={outputs
        .filter(hasOutputVisualisation)
        .map(parameter => ({ parameter, value: undefined }))}
      selections={selectedOutputs}
      activeStepId={activeStepId}
      mode="output"
      onChange={(parameter, _, checked) => onOutputsChange(parameter, checked)}
    />
  );

  return (
    <StyledPanel
      title={elementInstanceName}
      panelContent="DOEElementInstance"
      onClose={onClose}
      {...elementNamePopoverEvents}
    >
      <Box p={3}>
        <>
          {setActiveTab && (
            <InputOutputTabs
              activeTab={activeTab}
              tabsInfo={TABS_INFO}
              onChangeTab={setActiveTab}
              minimumTabWidth="140px"
            />
          )}
          <ScrollContainer>
            {activeTab === ElementDetailsTabs.INPUTS && inputsContent}
            {activeTab === ElementDetailsTabs.OUTPUTS && outputsContent}
            {elementNamePopover}
          </ScrollContainer>
        </>
      </Box>
    </StyledPanel>
  );
};

const StyledPanel = styled(Panel)({
  gridArea: 'instancePanel',
  height: '100%',
  justifySelf: 'end',
  zIndex: 2,
});

const ScrollContainer = styled('div')(({ theme }) => ({
  overflowX: 'hidden',
  overflowY: 'auto',
  borderBottomRightRadius: 'inherit',
  borderBottomLeftRadius: 'inherit',
  marginBottom: theme.spacing(3),
  flex: '1 1 auto',
}));

const TABS_INFO: TabsInfo<ElementDetailsTabs> = [
  { value: ElementDetailsTabs.INPUTS, label: 'Inputs' },
  { value: ElementDetailsTabs.OUTPUTS, label: 'Outputs' },
];

const InputOutputTabs = styled(Tabs<ElementDetailsTabs>)({
  minHeight: 35,
  height: 35,
  alignItems: 'flex-end',
  [`& .${tabClasses.root}`]: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

export default ProtocolElementInstancePanel;
