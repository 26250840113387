import React from 'react';

import CloseIcon from '@mui/icons-material/Close';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useCopyProtocolAndNavigate } from 'client/app/apps/protocols/api/ProtocolsAPI';
import WorkflowPreview from 'client/app/components/ExampleWorkflows/WorkflowPreview';
import { Protocol } from 'client/app/gql';
import IconButton from 'common/ui/components/IconButton';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  protocol: Protocol;
  onClose: () => void;
};

export function ProtocolDetails({ protocol, onClose }: Props) {
  const { handleCopyProtocolAndNavigate, loading } = useCopyProtocolAndNavigate();

  return (
    <>
      <StyledDialogHeader>
        <TypographyWithTooltip variant="h2">{protocol.name}</TypographyWithTooltip>
        <StyledDialogHeaderRightContentContainer>
          <IconButton
            icon={<CloseIcon />}
            onClick={onClose}
            color="inherit"
            size="small"
          />
        </StyledDialogHeaderRightContentContainer>
      </StyledDialogHeader>
      <Stack gap={5} overflow="auto" paddingRight={3} marginRight={-3} height="100%">
        <PreviewWrapper>
          <WorkflowPreview workflowId={protocol.workflow.id} />
        </PreviewWrapper>
        <StyledButtonsStack gap={5} direction="row">
          <Fab
            variant="extended"
            color="primary"
            onClick={() => handleCopyProtocolAndNavigate(protocol.id, protocol.version)}
            disabled={loading}
            sx={{ minWidth: '200px' }}
          >
            {loading ? (
              <CircularProgress color="inherit" />
            ) : (
              <Stack direction="row" gap={3} alignItems="center">
                <FileCopyIcon fontSize="small" />
                <Typography variant="button" whiteSpace="nowrap">
                  Duplicate protocol
                </Typography>
              </Stack>
            )}
          </Fab>
        </StyledButtonsStack>
        <StyledSummaryAndDescriptionStack
          rightMargin={224}
          height={80}
          flexGrow={1}
          gap={6}
        >
          <Stack gap={3}>
            <Typography variant="h2">Summary</Typography>
            <Typography variant="body1">{protocol.shortDescription}</Typography>
          </Stack>
          <Stack>
            <Typography variant="h2">Description</Typography>
            <Typography variant="body1" component="div">
              <MarkdownPreview markdown={protocol.protocol.displayDescription} />
            </Typography>
          </Stack>
        </StyledSummaryAndDescriptionStack>
      </Stack>
    </>
  );
}

const StyledDialogHeader = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start',
  minHeight: '38px',
  marginBottom: spacing(5),
  gap: spacing(3),
}));

const StyledDialogHeaderRightContentContainer = styled('div')({
  marginLeft: ' auto',
  display: 'flex',
  alignItems: 'center',
});

const PreviewWrapper = styled('div')(({ theme }) => ({
  height: 480,
  borderRadius: 10,
  border: `1px solid ${theme.palette.divider}`,
  overflow: 'hidden',
}));

const StyledSummaryAndDescriptionStack = styled(Stack, {
  shouldForwardProp: prop => prop !== 'rightMargin',
})<{ rightMargin: number }>(({ rightMargin }) => ({
  marginTop: '-65px',
  width: `calc(100% - ${rightMargin}px)`,
}));

const StyledButtonsStack = styled(Stack)({
  position: 'sticky',
  top: 0,
  justifyContent: 'end',
  alignItems: 'start',
  height: 'min-content',
});
