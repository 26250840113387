import React, { useState } from 'react';

import ClearIcon from '@mui/icons-material/Clear';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography';

import NameEditor from 'client/app/apps/protocols/NameEditor';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = Pick<TypographyProps, 'sx'> & {
  name: string;
  onSave: (newName: string) => void;
  canDelete?: boolean;
  onDelete: () => void;
};

/**
 * Shows the name of something with an icon to edit or optionally delete
 * it
 */
export default function EditableName({ sx, name, onSave, canDelete, onDelete }: Props) {
  const [isEditing, setIsEditing] = useState(false);

  const handleSave = (value: string) => {
    setIsEditing(false);
    onSave(value);
  };

  return (
    <EditableStack direction="row">
      {!isEditing ? (
        <>
          <Name sx={sx}>{name}</Name>
          <IconButton onClick={() => setIsEditing(true)}>
            <StyledEditIcon />
          </IconButton>
          {canDelete && (
            <IconButtonRight onClick={onDelete}>
              <StyledClearIcon />
            </IconButtonRight>
          )}
        </>
      ) : (
        <NameEditor name={name} onSave={handleSave} sx={sx} />
      )}
    </EditableStack>
  );
}

export const Name = styled(TypographyWithTooltip)(({ theme }) => ({
  ...theme.typography.h5,
  fontWeight: 700,
  color: theme.palette.text.primary,
  overflowX: 'hidden',
  userSelect: 'none',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

const EditableStack = styled(Stack)({
  width: '100%',
  alignItems: 'baseline',
});

const StyledEditIcon = styled(EditIcon)(({ theme: { spacing, palette } }) => ({
  width: spacing(5),
  height: spacing(5),
  color: palette.text.primary,
}));

const StyledClearIcon = styled(ClearIcon)(({ theme: { spacing, palette } }) => ({
  width: spacing(5),
  height: spacing(5),
  color: palette.error.main,
}));

const IconButtonRight = styled(IconButton)({
  marginLeft: 'auto',
});
