import React, { useState } from 'react';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useProtocolContext } from 'client/app/apps/protocols/context/ProtocolProvider';
import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import ExampleSimulation from 'client/app/apps/protocols/ExampleSimulation';
import MarkdownEditor from 'client/app/components/Markdown/MarkdownEditor';
import { Markdown } from 'common/lib/markdown';
import Colors from 'common/ui/Colors';
import useDebounce from 'common/ui/hooks/useDebounce';
import { useEnterKeyPress } from 'common/ui/hooks/useEnterKeyPress';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

const DESCRIBE_PROTOCOL_WIDTH = 1000;
const PAPER_MARGIN = 40;

export const DescribeProtocol = () => {
  const {
    name,
    shortDescription,
    displayDescription,
    updateProtocol,
    conflictDialog,
    exampleSimulation,
  } = useProtocolContext();

  const { protocolSteps } = useStepsContext();

  const [_, setIsMixPreviewFullcreen] = useState(false);

  const debouncedSaveDescription = useDebounce((newDescription: string) => {
    updateProtocol({
      protocol: { displayDescription: newDescription as Markdown, steps: protocolSteps },
    });
  }, 500);

  return (
    <Wrapper>
      <StyledPaper>
        <Container>
          <Title>Describe Protocol</Title>
          <Item>
            <ItemTitle>Name</ItemTitle>
            <Editor value={name} onSave={name => updateProtocol({ name })} />
          </Item>
          <Item>
            <ItemTitle>Short Description</ItemTitle>
            <Typography>
              <i>This will be shown when listing several protocols</i>
            </Typography>
            <Editor
              value={shortDescription}
              onSave={shortDescription => updateProtocol({ shortDescription })}
            />
          </Item>
          {!!exampleSimulation && (
            <Item>
              <ItemTitle>Preview</ItemTitle>
              <ExampleSimulation
                simulation={exampleSimulation}
                setIsMixPreviewFullcreen={setIsMixPreviewFullcreen}
              />
            </Item>
          )}
          <Item>
            <ItemTitle>Long Description</ItemTitle>
            <Typography>
              <i>This will be shown when showing the detailed view of this protocol</i>
            </Typography>
            <MarkdownEditor
              markdown={displayDescription}
              onChange={debouncedSaveDescription}
            />
          </Item>
          {conflictDialog}
        </Container>
      </StyledPaper>
    </Wrapper>
  );
};

type Props = {
  value: string;
  onSave: (value: string) => void;
};

function Editor({ value, onSave }: Props) {
  const [currentValue, setCurrentValue] = useState(value);

  const handleChange = useTextFieldChange(newValue => setCurrentValue(newValue));
  const saveNewValue = () => {
    onSave(currentValue);
  };
  const handleKeyDown = useEnterKeyPress(saveNewValue);

  return (
    <TextField
      variant="outlined"
      fullWidth
      value={currentValue}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onBlur={saveNewValue}
    />
  );
}

const Wrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  justifyContent: 'center',
  height: '100%',
  backgroundColor: Colors.GREY_10,
  overflowY: 'auto',
}));

const StyledPaper = styled(Paper)({
  display: 'flex',
  flex: `0 1 ${DESCRIBE_PROTOCOL_WIDTH}px`,
  margin: `${PAPER_MARGIN}px 0`,
  borderRadius: '8px',
});

const Container = styled(Stack)(({ theme: { spacing } }) => ({
  gap: spacing(6),
  margin: spacing(6),
  width: '100%',
}));

const Title = styled('h1')(({ theme }) => ({
  margin: `0 0 ${theme.spacing(7)} 0`,
  fontSize: 24,
  fontWeight: 700,
  fontFamily: 'Inter',
  lineHeight: '32px',
  '::after': {
    content: '"."',
    color: Colors.PRIMARY_MAIN,
  },
}));

const Item = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(3),
}));

const ItemTitle = styled('h2')(() => ({
  margin: 0,
  fontSize: 14,
  fontWeight: 600,
  fontFamily: 'Roboto',
  lineHeight: '16px',
}));
