import React, { useState } from 'react';

import { useQuery } from '@apollo/client';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { QUERY_UNIQUE_TAGS } from 'client/app/api/gql/queries';
import { usePublishProtocolAndNavigate } from 'client/app/apps/protocols/api/ProtocolsAPI';
import IconButton from 'common/ui/components/IconButton';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = DialogProps<void> & {
  id: ProtocolId;
  version: ProtocolVersion;
};

export const PublishProtocolDialog = ({ isOpen, onClose, id, version }: Props) => {
  const { handlePublishProtocolAndNavigate, loading } = usePublishProtocolAndNavigate();

  const { data } = useQuery(QUERY_UNIQUE_TAGS, {
    variables: {
      entityClassName: 'Protocol',
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  });

  const handleOnClose = () => {
    onClose();
  };

  const [tags, setTags] = useState<string[]>([]);
  const [isPublic, setIsPublic] = useState(false);

  const handleOnPublish = () => {
    void handlePublishProtocolAndNavigate(id, version, isPublic, tags);
  };
  const options = data?.uniqueTags || [];

  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <StyledDialogHeader>
        <Typography variant="h3">Publish protocol</Typography>
        <StyledDialogHeaderRightContentContainer>
          <IconButton
            icon={<CloseIcon />}
            onClick={handleOnClose}
            color="inherit"
            size="small"
            disabled={loading}
          />
        </StyledDialogHeaderRightContentContainer>
      </StyledDialogHeader>
      <Main>
        <Stack gap={3}>
          <Typography variant="body1" fontWeight={500}>
            Tags
          </Typography>
          <Autocomplete
            multiple
            freeSolo
            size="small"
            options={options}
            value={tags}
            onChange={(_, newTags) => setTags(newTags)}
            renderInput={params => (
              <TextField {...params} variant="outlined" placeholder="Select tag(s)..." />
            )}
          />
        </Stack>
        <Stack gap={3}>
          <FormControlLabel
            control={
              <Switch checked={isPublic} onChange={e => setIsPublic(e.target.checked)} />
            }
            label="Protocol is public"
          />
        </Stack>
      </Main>
      <Actions>
        <Button color="primary" onClick={handleOnPublish} sx={{ padding: 0 }}>
          Publish
        </Button>
      </Actions>
    </Dialog>
  );
};

const StyledDialogHeader = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '38px',
  padding: spacing(6),
  minWidth: '600px',
}));

const Main = styled('main')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(5),
  padding: spacing(0, 6),

  overflow: 'auto',
}));

const Actions = styled(DialogActions)(({ theme: { spacing } }) => ({
  padding: spacing(6),
}));

const StyledDialogHeaderRightContentContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
