import React from 'react';

import Dialog from '@mui/material/Dialog';
import { styled } from '@mui/material/styles';

import { ProtocolDetails } from 'client/app/apps/protocols/ProtocolsDetails';
import { Protocol } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = DialogProps<void> & { protocol: Protocol };

export function ProtocolsDialog({ isOpen, onClose, protocol }: Props) {
  return (
    <StyledDialog
      open={isOpen}
      onClose={() => onClose()}
      maxWidth="lg"
      fullWidth
      TransitionProps={{
        unmountOnExit: true,
      }}
    >
      <ProtocolDetails protocol={protocol} onClose={onClose} />
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)(({ theme: { spacing } }) => ({
  '& .MuiDialog-paper': {
    backgroundColor: Colors.GREY_5,
    padding: spacing(6),
    height: '100%',
  },
}));
