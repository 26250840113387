import React, { useState } from 'react';

import { Route, RouteComponentProps } from 'react-router-dom';

import * as SimulationsApi from 'client/app/api/SimulationsApi';
import SimulationDetails from 'client/app/apps/simulation-details/SimulationDetails';
import UIErrorBox from 'client/app/components/UIErrorBox';
import { simulationRoutes } from 'client/app/lib/nav/actions';
import { ScreenRegistry } from 'client/app/registry';
import { isUUIDInfer } from 'common/lib/strings';
import LinearProgress from 'common/ui/components/LinearProgress';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { ScreenIds } from 'common/ui/components/simulation-details/simulationDetailsScreenIds';
import { NavigationRoute } from 'common/ui/navigation';

// Extract the type of the params from the route, so that we don't have to list
// the params here again. It is important the param list only exists in one place.
type GetRouteParams<RouteType> = RouteType extends NavigationRoute<infer T> ? T : never;

type RouteParams = GetRouteParams<typeof simulationRoutes.simulationDetailsSubscreen>;

/**
 * Renders the Simulation Details UI.
 */
function SimulationDetailsScreen() {
  const navigationForRedirect = useNavigation();
  const [redirectError, setRedirectError] = useState('');

  const checkSimulationRedirect = SimulationsApi.useCheckRedirect();

  return (
    <Route
      path={simulationRoutes.simulationDetailsSubscreen.pathTemplate}
      exact
      render={(props: RouteComponentProps<Partial<RouteParams>>) => {
        const { simulationId: simulationOrJobId = '', subscreenId } = props.match.params;
        if (isUUIDInfer<SimulationId>(simulationOrJobId)) {
          return (
            <SimulationDetails
              key={simulationOrJobId}
              simulationId={simulationOrJobId}
              subscreenId={(subscreenId || ScreenIds.RESOURCES) as ScreenIds}
              sourceScreenId={ScreenRegistry.SIMULATION_DETAILS}
            />
          );
        } else {
          // We received a link to a legacy Job. People still have old links stored in documents,
          // browser bookmarks etc.
          // Each legacy Job was migrated from the legacy Datastore to a Simulations in Postgres.
          // Redirect to the new Simulation.
          (async () => {
            const simulationId = await checkSimulationRedirect(simulationOrJobId);
            if (simulationId) {
              navigationForRedirect.navigate(
                simulationRoutes.simulationDetailsSubscreen,
                {
                  simulationId,
                  subscreenId,
                },
                { method: 'replace' },
              );
            } else {
              setRedirectError('Sorry, we could not find this Simulation.');
            }
          })();
          if (redirectError) {
            return <UIErrorBox>{redirectError}</UIErrorBox>;
          } else {
            return <LinearProgress />;
          }
        }
      }}
    />
  );
}

export default SimulationDetailsScreen;
